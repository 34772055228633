import {graphql, navigate} from 'gatsby';
import * as React from 'react';

import Layout from '../components/layout';
import ArticleHeader from '../components/pages/ArticleHeader';
import Seo from '../components/seo';
import './404.scss';

const NotFoundPage = () => {
  const onHomeClick = async () => {
    await navigate('/');
  };

  return (
    <Layout>
      <Seo title="404: Not Found" />
      <ArticleHeader />
      <div className={'container'}>
        <p className={'container__text'}>
          Nothing to be found here, go
          <span className={'container__link'} onClick={onHomeClick}>
            home?
          </span>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
