import {navigate} from 'gatsby';
import * as React from 'react';

import BACK from '../../images/back.webp';
import FARRELLY_FIRE from '../../images/farrelly.gif';
import './ArticlePage.scss';

const ArticleHeader = () => {
  const goHome = () => navigate('/');
  const goBack = () => navigate(-1);

  return (
    <div className={'article_page'}>
      <nav role={'button'} onClick={goBack}>
        <img src={BACK} alt={'Back button'} className={'back'} />
      </nav>
      <div className={'image'} role={'button'} onClick={goHome}>
        <img src={FARRELLY_FIRE} alt={'The Farrelly logo'} className={'image__element'} />
      </div>
    </div>
  );
};

export default ArticleHeader;
